
// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ProductService from 'src/services/product'
import { createInquiry } from '../inquiry';


export const getProductFormList = createAsyncThunk(
	'product/getProductFormList',
	async (args, { getState, dispatch }) => {
		try {
			const response = await ProductService.productForm.getAll();
			return response;
		} catch (error) {
			console.log(error)
		}
	}
)

export const getSuggestedProducts = createAsyncThunk(
	'product/getSuggestedProducts',
	async ({idAnsweredQuestion=[]}, { getState, dispatch }) => {
		try {
			let allProductsValue = []
			idAnsweredQuestion.map((elem, index) => {
				allProductsValue = allProductsValue.concat(elem.products)
			})
			console.log('check value', idAnsweredQuestion)
			allProductsValue = allProductsValue.join(',') // to be able to spli in the back to get an array
			const response = await ProductService.productForm.getSuggestedProducts({
				idAnsweredQuestion,
				allProductsValue,
			});
			console.log("response: ",response)
			console.log("OK");
			return response;
		} catch (error) {
			console.log(error)
		}
	}
)

export const getProductSearch = createAsyncThunk(
	'product/getProductSearch',
	async ({search, autocomplete}, { getState, dispatch }) => {
		try {
			console.log('Dispatching getProductSearch:', search, autocomplete); // Check if the search term is correctly passed
			const response = await ProductService.product.searchAll(search, autocomplete);
			console.log('Response:', response); // Check the response from the ProductService
			return response;
		} catch (error) {
			console.log(error)
		}
	}
)



// export const getProductForm = createAsyncThunk(
// 	'inquiry/getProductForm',
// 	async (productFormId, { getState, dispatch }) => {
// 		const response = await ProductService.productForm.getOne(productFormId);
// 		return response;
// 	}
// )
export const currentQuestionHasSubquestions = function (state) {
	const formStep = state.product.productForm.formStep
	const hasSubquestions = (options) => {
		let toFind = undefined
		if (options) {
			toFind = options.find((option)=> {
				const keys = Object.keys(option)
				return keys.includes('subQuestion')
			})
		}
		return toFind != undefined
	}
	const currentForm = state.product.productForm.currentForm
	if (typeof(currentForm) === typeof("") || Object.keys(currentForm).length == 0)
		return false
	const currentQuestion = currentForm.questions[formStep]

	return hasSubquestions(currentQuestion?.options)
}

export const ProductSlice = createSlice({
	name: 'product',
	initialState: {
		productFormList: {
            productForms: []
        },
		productForm: {
			currentForm: {},
			questionNames: {},
			formStep: 0,
			subQuestionsArray: {},
			accordionExpanded: false
		},
		productFormResponse: [{
			step: 0,
			response: [{
				responseType: 'null',
				response: '',
				products: [],
				dimensions: [],
			}],
		}],
		productSuggestionFromForm: [],
		productCart: {
			userId: '',
			products: [],
			amounts: 0,
			promo: 0,
			days: 1,
			livraison: 0,
			récupération: 0,
			installation: 0,
			désinstallation: 0,
			fullamounts: 0
		},
		productSearchList: []
	},
	reducers: {
		clearProductCart: (state) => {
			state.productCart = {
				userId: '',
				products: [],
				amounts: 0,
				promo: 0,
				livraison: 0,
				récupération: 0,
				installation: 0,
				désinstallation: 0,
				fullamounts: 0
			}
		},
		updateProductCart: (state,action) => {
			const cart = state.productCart.products.findIndex(obj => obj.articleCode === action.payload.products.articleCode)
			if (cart !== -1) {
				state.productCart.products[cart].prodQuantity += action.payload.products.prodQuantity;
			} else {
				state.productCart.products = state.productCart.products.concat(action.payload.products);
			}
			// action.payload.price here is receive by doing action.payload.products.prodQuantity * the price of the product
			state.productCart.amounts += action.payload.price
			state.productCart.fullamounts = ((((parseFloat((((state.productCart.amounts) - state.productCart.promo) / 100) * 8) + parseFloat(state.productCart.amounts))) + (state.productCart.livraison ? parseFloat(state.productCart.livraison) : 0)) + (state.productCart.récupération ? parseFloat(state.productCart.récupération) : 0) + (state.productCart.installation ? parseFloat(state.productCart.installation) : 0) + (state.productCart.désinstallation ? parseFloat(state.productCart.désinstallation) : 0) - (state.productCart.promo ? parseFloat(state.productCart.promo) : 0)).toFixed(2)
		},
		updatePriceInfo: (state, action) => {
			state.productCart.promo = action.payload.promo
			state.productCart.livraison = action.payload.livraison
			state.productCart.récupération = action.payload.récupération
			state.productCart.installation = action.payload.installation
			state.productCart.désinstallation = action.payload.désinstallation
			state.productCart.fullamounts = ((((parseFloat((((state.productCart.amounts) - state.productCart.promo) / 100) * 8) + (parseFloat(state.productCart.amounts)))) + (state.productCart.livraison ? parseFloat(state.productCart.livraison) : 0)) + (state.productCart.récupération ? parseFloat(state.productCart.récupération) : 0) + (state.productCart.installation ? parseFloat(state.productCart.installation) : 0) + (state.productCart.désinstallation ? parseFloat(state.productCart.désinstallation) : 0) - (state.productCart.promo ? parseFloat(state.productCart.promo) : 0)).toFixed(2)
		},
		updateQuantityProduct: (state, action) => {
			if (state.productCart.products[action.payload.index] && state.productCart.products[action.payload.index].prodQuantity) {
				state.productCart.amounts -= state.productCart.products[action.payload.index].prodQuantity * state.productCart.products[action.payload.index].unitPrice
				state.productCart.products[action.payload.index].prodQuantity = action.payload.prodQuantity
				state.productCart.amounts += state.productCart.products[action.payload.index].prodQuantity * state.productCart.products[action.payload.index].unitPrice
			}
			state.productCart.fullamounts = (((((parseFloat((((state.productCart.amounts)-state.productCart.promo)/100)*8)) + parseFloat(state.productCart.amounts))) + (state.productCart.livraison ? parseFloat(state.productCart.livraison) : 0)) + (state.productCart.récupération ? parseFloat(state.productCart.récupération) : 0) + (state.productCart.installation ? parseFloat(state.productCart.installation) : 0) + (state.productCart.désinstallation ? parseFloat(state.productCart.désinstallation) : 0) - (state.productCart.promo ? parseFloat(state.productCart.promo) : 0)).toFixed(2)
			console.log("full amount: ", state.productCart.fullamounts, )
		},
		updateDaysProductCart: (state, action) => {
			if (action.payload <= 1) {
				state.productCart.days = 1
			} else {
				state.productCart.days = action.payload + 1
			}
			state.productCart.fullamounts = ((((parseFloat(((state.productCart.amounts - state.productCart.promo) / 100) * 8) + parseFloat(state.productCart.amounts))) + (state.productCart.livraison ? parseFloat(state.productCart.livraison) : 0)) + (state.productCart.récupération ? parseFloat(state.productCart.récupération) : 0) + (state.productCart.installation ? parseFloat(state.productCart.installation) : 0) + (state.productCart.désinstallation ? parseFloat(state.productCart.désinstallation) : 0) - (state.productCart.promo ? parseFloat(state.productCart.promo) : 0)).toFixed(2)
		},
		editProductCart: (state, action) => {
			state.productCart = action.payload
		},
		changeElemProductCart: (state, action) => {
			const {elem, value} = action.payload;

			state.productCart[elem] = value;
		},
		deleteProductCart: (state,action) => {
			const indexToDelete = action.payload;
			// console.log("current products: ", state.productCart)
			state.productCart.amounts -= state.productCart.products[indexToDelete].unitPrice * state.productCart.products[indexToDelete].prodQuantity
			state.productCart.products = state.productCart.products.filter(
				(_, index) => index !== indexToDelete
			);
			state.productCart.fullamounts = ((((parseFloat(((state.productCart.amounts - state.productCart.promo) / 100) * 8) + parseFloat(state.productCart.amounts))) + (state.productCart.livraison ? parseFloat(state.productCart.livraison) : 0)) + (state.productCart.récupération ? parseFloat(state.productCart.récupération) : 0) + (state.productCart.installation ? parseFloat(state.productCart.installation) : 0) + (state.productCart.désinstallation ? parseFloat(state.productCart.désinstallation) : 0) - (state.productCart.promo ? parseFloat(state.productCart.promo) : 0)).toFixed(2)

			
			// console.log("INSIDE THE STORE: ",action.payload)
			// console.log("INSIDE THE amounts: ",state.productCart.amounts)
			// console.log("INSIDE THE STORE: ",state.productCart.products)
		},
		updateProductForm: (state, action) => {
			console.log('ac', action.payload)
			state.productForm.currentForm = action.payload;
		},
		resetProductForm: (state, action) => {
			state.productForm = {
				currentForm: {},
				questionNames: {},
				formStep: 0,
				subQuestionsArray: {},
				accordionExpanded: false
			}
		},
		setQuestion: (state, action) => {
			state.productFormResponse = action.payload
		},
		answerQuestion: (state, action) => {
			const { step, products, response } = action.payload;
			const existingIndex = state.productFormResponse.findIndex(obj => obj.step === step);

			if (existingIndex !== -1) {
				state.productFormResponse[existingIndex] = {
					...state.productFormResponse[existingIndex],
					response: response,
				};
			} else {
				state.productFormResponse.push({
				step: step,
				response: response,
				});
			}
		},
		resetQuestion: (state, action) => {
			state.productFormResponse = [{
				step: 0,
				response: [{
					responseType: 'null',
					response: '',
					products: [],
					dimensions: [],
				}]
			}]
		},
		addProductSuggestionFromForm: (state, action) => {
			state.productSuggestionFromForm = state.productSuggestionFromForm.concat(action.payload)
		},
		removeProductSuggestionFromForm: (state, action) => {
			state.productSuggestionFromForm = []
		},
		updateQuestionNames: (state, action) => {
			let {formName, questions} = action.payload
			let questionNames = questions.map((question) => question.questionText)
			state.productForm.questionNames = { [formName]: questionNames };
		},
		incrementFormStep: (state, action) => {
			let formName = state.productForm.currentForm.formName
			let nbQuestions = state.productForm.questionNames[formName].length;
			if (nbQuestions > 0) {
				if (state.productForm.formStep < nbQuestions -1) {
					state.productForm.formStep += 1;
				}
			}
		},
		decrementFormStep: (state, action) => {
			if (state.productForm.formStep > 0)
				state.productForm.formStep -= 1;
		},
		updateFormStep: (state, action) => {
			state.productForm.formStep = action.payload
		},
		updateSubquestionsArray: (state, action) =>{
			const { step, value } = action.payload;
			console.log(state.productForm.subQuestionsArray)
			const filteredSubQuestionsArr = Object.keys(state.productForm.subQuestionsArray).filter(
				(elem, key) => key < step
			);
			const filteredSubQuestionsObj = {}
			filteredSubQuestionsArr.forEach((elem) => (
				filteredSubQuestionsObj[elem] = state.productForm.subQuestionsArray[elem]
			))
			console.log(filteredSubQuestionsObj)
			state.productForm.subQuestionsArray = {
				...filteredSubQuestionsObj,
				...value
			};
		},
		resetSubquestionsArray: (state, action) =>{
			state.productForm.subQuestionsArray = {}
		},
		toggleAccordion: (state, action) => {
			state.productForm.accordionExpanded = !state.productForm.accordionExpanded
		},
		updateAccordionExpanded: (state, action) => {
			state.productForm.accordionExpanded = action.payload
		},
		resetProduct: (state, action) => {
			return {
				...state,
				productFormList: {
					productForms: []
				},
				productForm: {
					currentForm: {},
					questionNames: {},
					formStep: 0,
					subQuestionsArray: {},
					accordionExpanded: false
				},
				productFormResponse: [
					{
						step: 0,
						response: [{
							responseType: 'null',
							response: '',
							products: [],
							dimensions: [],
						}]
					}
				],
				productSuggestionFromForm: [],
				productCart: {
					userId: '',
					products: [],
					amounts: 0,
					promo: 0,
					livraison: 0,
					récupération: 0,
					installation: 0,
					désinstallation: 0,
					fullamounts: 0
				},
				productSearchList: []
			};
		},
		resetAllProductButNotTheCart: (state, action) => {
			return {
					...state,
					productFormList: state.productFormList,
				productForm: {
					currentForm: {},
					questionNames: {},
					formStep: 0,
					subQuestionsArray: {},
					accordionExpanded: false
				},
				productFormResponse: [
					{
						step: 0,
						response: [{
							responseType: 'null',
							response: '',
							products: [],
							dimensions: [],
						}]
					}
				],
				productSuggestionFromForm: [],
				// Conservez la partie productCart inchangée
				productCart: state.productCart,
				productSearchList: []
			};
		},
	},
	extraReducers: builder => {
		builder.addCase(getProductFormList.fulfilled, (state, action) => {
			state.productFormList = {
				...state.productFormList,
				productForms: action.payload.productForms,
			};
		}),
		builder.addCase(getProductSearch.fulfilled, (state, action) => {
			console.log('action.payload', action.payload)
			state.productSearchList = action.payload
		}),
		builder.addCase(getSuggestedProducts.fulfilled, (state, action) => {
			// console.log('DONE', action.payload['products'])
			state.productSuggestionFromForm = action?.payload['products']
		}),
		builder.addCase(createInquiry.fulfilled, (state, action) => {
			state = {
				productFormList: {
					productForms: []
				},
				productForm: {
					currentForm: {},
					questionNames: {},
					formStep: 0,
					subQuestionsArray: {},
					accordionExpanded: false
				},
				productFormResponse: [{
					step: 0,
					response: [{
						responseType: 'null',
						response: '',
						products: [],
						dimensions: [],
					}]
				}],
				productSuggestionFromForm: [],
				productCart: {
					userId: '',
					products: [],
					amounts: 0,
					promo: 0,
					livraison: 0,
					récupération: 0,
					installation: 0,
					désinstallation: 0,
					fullamounts: 0
				},
				productSearchList: []
			}
		})
	}
})

export const {
	updateQuantityProduct,
	updatePriceInfo,
	resetProduct,
	setQuestion,
	updateDaysProductCart,
	resetAllProductButNotTheCart,
	updateProductForm,
	resetProductForm,
	updateQuestionNames,
	incrementFormStep,
	decrementFormStep,
	updateFormStep,
	updateSubquestionsArray,
	resetSubquestionsArray,
	answerQuestion,
	toggleAccordion,
	updateAccordionExpanded,
	updateProductCart,
	editProductCart,
	changeElemProductCart,
	deleteProductCart,
	addProductSuggestionFromForm,
	removeProductSuggestionFromForm,
	clearProductCart

} = ProductSlice.actions

export default ProductSlice.reducer
