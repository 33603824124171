
// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';

export const NotifSlice = createSlice({
	name: 'notif',
	initialState: {
		message: null,
	},
	reducers: {
		showMessage: (state, action) => {
			state.message = action.payload;
		},
		clearMessage: (state) => {
			state.message = null;
		},
		errorToast: (state, action) => {
			toast.error(action.payload, {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				theme: "colored",
			});
		},
		successToast: (state, action) => {
			toast.success(action.payload, {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				theme: "colored",
			});
		},
	}});

export const {
	showMessage,
	clearMessage,
	errorToast,
	successToast,
} = NotifSlice.actions;

export default NotifSlice.reducer;
