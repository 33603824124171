import apiClient, {apiURLs} from "../apiClient"




async function productSearchAll(searchQuery, autocomplete) {
    try {
        const response = await apiClient.get(`${apiURLs.product}search`, 
        {
            params: {
                search: searchQuery,
                autocomplete: autocomplete
            }
        })
        return response.data
    } catch (error) {
        console.error(error);
        throw Error('Aucun produit correspondant à la recherche');
    }
}

async function suggestedProduct(idAnsweredQuestionQuery) {
    try {
        const response = await apiClient.post(`${apiURLs.productForm}getSuggestedProducts`,
        {
                idAnsweredQuestion: idAnsweredQuestionQuery,
        });

        return response.data
    } catch (error) {
        // TODO::error handling
        console.error(error);
        throw Error('Impossible de récuperer les produits');
    }
}

async function productFormGetAll() {
    try {
        const response = await apiClient.get(apiURLs.productForm);
        return response.data
    } catch (error) {
        // TODO::error handling
        console.error(error);
        throw Error('Impossible de récuperer les formulaires produits');
    }
}

async function productFormGetOne(productFormId) {
    try {
        const response = await apiClient.get(`${apiURLs.productForm}${productFormId}`, {
            ...data,
        });

        return response.data
    } catch (error) {
        // TODO::error handling
        console.error(error);
        throw Error('Impossible de récuperer le formulaire produits');
    }
}

// async function update(inquiryId) {
//     try {
//         const response = await apiClient.get(`/inquiry/${inquiryId}`, {
//             ...data,
//         });

//         console.log('getALL inquiries', data)
//         return response.data
//     } catch (error) {
//         // TODO::error handling
//         console.error(error);
//         throw Error('Impossible de mettre la demande à jour');
//     }
// }

// //delete is a reserved js keyword, so we name it deleteInquiry
// async function deleteInquiry(inquiryId) {
//     try {
//         const response = await apiClient.delete(`/inquiry/${inquiryId}`);
//         return response.data
//     } catch (error) {
//         // TODO::error handling
//         console.error(error);
//         throw Error('Impossible de supprimer la demande');
//     }
// }


const ProductService = {
    productForm: {
        getAll: productFormGetAll,
        getOne: productFormGetOne,
        getSuggestedProducts: suggestedProduct
    }, 
    product: {
        searchAll: productSearchAll
    }
}

export default ProductService