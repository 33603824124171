import apiClient from "../apiClient"

async function create(inquiryType, data) {
    try {
        const response = await apiClient.post('/inquiry', {
            inquiryType: inquiryType,
            ...data,
        });
        //alert("Demande sauvegardée")
        return response
    } catch (error) {
        // TODO::error handling
        throw Error('Impossible de créer une nouvelle demande');
    }
}

async function getAll(page, limit) {
    try {
        const response = await apiClient.get('/inquiry/');

        return response.data
    } catch (error) {
        // TODO::error handling
        console.error(error);
    }
}

async function getOne(inquiryId) {
    try {
        const response = await apiClient.get(`/inquiry/${inquiryId}`, {
            ...data,
        });

        console.log('getALL inquiries', data)
        return response.data
    } catch (error) {
        // TODO::error handling
        console.error(error);
        throw Error('Impossible de récuperer les demandes');
    }
}

async function createCustomer(infoNewCustomer) {
    // console.log("avant le axios", infoNewCustomer)
    try {
        const response = await apiClient.post('/customer-details/addCustomer', infoNewCustomer);

        return response.data
    } catch (error) {
        // TODO::error handling
        console.error(error);
        throw Error('Impossible de créer un nouveau Customer');
    }
}

async function editCustomer(customerID, infoNewCustomer) {
    try {
        const response = await apiClient.put('/customer-details/'+customerID, infoNewCustomer);

        return response.data
    } catch (error) {
        // TODO::error handling
        console.error(error);
        throw Error('Impossible de créer un nouveau Customer');
    }
}

async function createEntreprise(infoNewCustomer) {
    // console.log("avant le axios", infoNewCustomer)
    try {
        const response = await apiClient.post('/entreprise-details/', infoNewCustomer);

        return response.data
    } catch (error) {
        // TODO::error handling
        console.error(error);
        throw Error('Impossible de créer un nouveau Customer');
    }
}

async function editEntreprise(companyID, infoNewCustomer) {
    // console.log("avant le axios", infoNewCustomer)
    try {
        const response = await apiClient.put('/entreprise-details/'+companyID, infoNewCustomer);

        return response.data
    } catch (error) {
        // TODO::error handling
        console.error(error);
        throw Error('Impossible de créer un nouveau Customer');
    }
}
// async function update(inquiryId) {
//     try {
//         const response = await apiClient.get(`/inquiry/${inquiryId}`, {
//             ...data,
//         });

//         console.log('getALL inquiries', data)
//         return response.data
//     } catch (error) {
//         // TODO::error handling
//         console.error(error);
//         throw Error('Impossible de mettre la demande à jour');
//     }
// }

async function editInquiry(inquiryId, infoNewCustomer) {
    try {
        const response = await apiClient.put(`/inquiry/${inquiryId}`, {
            inquiryType: 'draft',
            ...infoNewCustomer,
        });
        return response.data
    } catch (error) {
        // TODO::error handling
        console.error(error);
        throw Error('Impossible de supprimer la demande');
    }
}

//delete is a reserved js keyword, so we name it deleteInquiry
async function deleteInquiry(inquiryId) {
    try {
        const response = await apiClient.delete(`/inquiry/${inquiryId}`);
        return response.data
    } catch (error) {
        // TODO::error handling
        console.error(error);
        throw Error('Impossible de supprimer la demande');
    }
}


const InquiryService = {
    create,
    getAll,
    getOne,
    createCustomer,
    editInquiry,
    deleteInquiry,
    editCustomer,
    createEntreprise,
    editEntreprise
}

export default InquiryService