import axios from 'axios';
import dns from 'src/components/DNS/dns'



export const apiURLs = {
  inquiry: '/inquiry/',
  product: '/product/',
  productForm: '/product/form/'
}

// Next we make an 'instance' of it
const instance = axios.create({
// .. where we make our configurations
    baseURL: dns,
    headers: {
    'Content-Type': 'application/json'
    }
});

instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
  });


export default instance;