
// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import InquiryService from 'src/services/inquiry'
import { resetProductForm } from '../product';
import { useDispatch } from 'react-redux';
import { successToast, errorToast } from '../notif';
import { toast } from 'react-toastify';

export const createInquiry = createAsyncThunk(
	'inquiry/createInquiry',
	async (inquiryType, { getState, dispatch }) => {
		const currentState = getState();
		const newInquiryData = currentState.inquiry.newInquiry;
		const productCartData = currentState.product.productCart;
		let productFormat = []
		productCartData.products.map((elem) => {
			productFormat.push({
				product: elem.id ? elem.id : elem._id,
				prodQuantity: elem.prodQuantity,
			})
		})
		const valueToSend = {
			inquiryType: inquiryType,
			createdBy: undefined,
			customerInfo: {
				companyID: newInquiryData.customerInfo.companyID,
				customerID: newInquiryData.customerInfo.customerID,
				refChantier: newInquiryData.customerInfo.refChantier
			},
			origineDemande: newInquiryData.origineDemande,
			demandeClient: newInquiryData.demandeClient,
			dureeLocation: {
				dateDebut: newInquiryData.dureeLocation.dateDebut == null ? '' : newInquiryData.dureeLocation.dateDebut,
				dateFin: newInquiryData.dureeLocation.dateFin == null ? '' : newInquiryData.dureeLocation.dateFin,
				commentaire: newInquiryData.dureeLocation.commentaire,
				affaireObtenue: newInquiryData.dureeLocation.affaireObtenue,
				dateReponse: newInquiryData.dureeLocation.dateReponse == null ? '' : newInquiryData.dureeLocation.dateReponse,
				tempsEstime: newInquiryData.dureeLocation.tempsEstime
			},
			googleMaps: newInquiryData.googleMaps,
			localisationChantier: newInquiryData.localisationChantier,
			installationChantier: newInquiryData.installationChantier,
			productCart: {
				userId: productCartData.userId,
				products: productFormat,
				amounts: productCartData.amounts ? productCartData.amounts : 0,
				promo: productCartData.promo,
				livraison: productCartData.livraison,
				récupération: productCartData.récupération,
				installation: productCartData.installation,
				désinstallation: productCartData.désinstallation,
				fullamounts: productCartData.fullamounts
			},
			productForm: {
				currentForm: currentState.product.productForm.currentForm,
				productFormResponse: currentState.product.productFormResponse
			},
			commentaire: newInquiryData.commentaire
		}
		if (currentState.inquiry.newInquiry.editID === 0) {
			const response = await InquiryService.create(inquiryType, valueToSend);
			return response
		} else {
			const response = await InquiryService.editInquiry(currentState.inquiry.newInquiry.editID, valueToSend)
			return response
		}
	}
)

export const getInquiryList = createAsyncThunk(
	'inquiry/getInquiryList',
	async (args, { getState, dispatch }) => {
		const response = await InquiryService.getAll();
		return response;
	}
)

export const deleteInquiry = createAsyncThunk(
	'inquiry/deleteInquiry',
	async (id, { getState, dispatch }) => {
		const response = await InquiryService.deleteInquiry(id);
		dispatch(getInquiryList())
		return response;
	}
)

export const createCustomer = createAsyncThunk(
	'inquiry/createCustomer',
	async (infoNewCustomer, { getState, dispatch }) => {
		// console.log("test avant l'envoie", infoNewCustomer)
		const response = await InquiryService.createCustomer(infoNewCustomer);
		return response;
	}
)

export const editCustomer = createAsyncThunk(
	'inquiry/editCustomer',
	async ({customerID, infoNewCustomer}, { getState, dispatch }) => {
		const response = await InquiryService.editCustomer(customerID, infoNewCustomer);
		return response;
	}
)

export const createEntreprise = createAsyncThunk(
	'inquiry/createEntreprise',
	async (infoNewCustomer, { getState, dispatch }) => {
		// console.log("test avant l'envoie", infoNewCustomer)
		const response = await InquiryService.createEntreprise(infoNewCustomer);
		return response;
	}
)

export const editEntreprise = createAsyncThunk(
	'inquiry/editEntreprise',
	async ({companyID, infoNewCustomer}, { getState, dispatch }) => {
		// console.log("test avant l'envoie", infoNewCustomer)
		const response = await InquiryService.editEntreprise(companyID, infoNewCustomer);
		return response;
	}
)

export const InquirySlice = createSlice({
	name: 'inquiry',
	initialState: {
		newInquiry: {
			editID: 0,
			customerInfo: {
				companyID: '',
				customerID: '',
				numClient: '',
				nomEntreprise: '',
				designation: '',
				nomClient: '',
				email: '',
				telephone: '',
				fonction: '',
				refChantier: ''
			},
			origineDemande: {
				typeDemande: ''
			},
			dureeLocation: {
				dateDebut: '',
				dateFin: '',
				commentaire: '',
				affaireObtenue: '',
				dateReponse: '',
				tempsEstime: ''
			},
			demandeClient: {
				badgeInput: "",
				badges: []
			},
			googleMaps: {
				formattedAddress: '',
				latitude: '',
				longitude: '',
				placeId: ''
			},
			filtre: {

			},
			localisationChantier: {
				adresseLivraison: '',
				accessibiliteLivraison: '',
				contactLivraison: '',
				livraison: '',
				telLivraison: ''
			},
			installationChantier: {
				adresseInstallation: '',
				accessibiliteInstallation: '',
				contactInstallation: '',
				installation: '',
				telInstallation: '',
			},
			commentaire: ''
		},
		inquiryList: {
			inquiries: [],
			limit: 10,
			currentPage: 1,
			totalPages: 1
		}
	},
	reducers: {
		updateCoordonneeForm: (state, action) => {
			state.newInquiry.customerInfo = action.payload;
		},
		updateOneCoordonneeForm: (state, action) => {
			const { field, value } = action.payload;
			state.newInquiry.customerInfo[field] = value;
		},
		updateEntrepriseCoordonneeForm: (state, action) => {
			const { companyID, numClient, nomEntreprise} = action.payload;
			state.newInquiry.customerInfo.companyID = companyID;
			state.newInquiry.customerInfo.numClient = numClient;
			state.newInquiry.customerInfo.nomEntreprise = nomEntreprise;
		},
		updateCustomerCoordonneeForm: (state, action) => {
			const { customerID,	designation, nomClient, email, telephone, fonction, refChantier} = action.payload;
			state.newInquiry.customerInfo.customerID = customerID;
			state.newInquiry.customerInfo.designation = designation;
			state.newInquiry.customerInfo.nomClient = nomClient;
			state.newInquiry.customerInfo.email = email;
			state.newInquiry.customerInfo.telephone = telephone;
			state.newInquiry.customerInfo.fonction = fonction;
			state.newInquiry.customerInfo.refChantier = refChantier;
		},
		updateCommentaire: (state, action) => {
			state.newInquiry.commentaire = action.payload.commentaire
		},
		updateDureeLocationForm: (state, action) => {
			state.newInquiry.dureeLocation = action.payload;
		},
		updateOneDureeLocationForm: (state, action) => {
			const { field, value } = action.payload;
			state.newInquiry.dureeLocation[field] = value;
		},
		updateDemandeOrigin: (state, action) => {
			state.newInquiry.origineDemande = action.payload;
		},
		updateDemandeClientForm: (state, action) => {
			state.newInquiry.demandeClient = action.payload;
			state.newInquiry.demandeClient.badgeInput !== "" ?
				state.newInquiry.demandeClient.badges = action.payload.badgeInput.split(',').
					map((element) => element.trim())
					.filter((element) => element != null && element != undefined && element != "")
				:
				state.newInquiry.demandeClient.badges = ""
		},
		updateGoogleMapsForm: (state, action) => {
			state.newInquiry.googleMaps = action.payload;
		},
		updateFiltreForm: (state, action) => {
			state.newInquiry.filtre = action.payload;
		},
		updateLocalisationChantierForm: (state, action) => {
			state.newInquiry.localisationChantier = action.payload;
		},
		updateInstallationChantierForm: (state, action) => {
			state.newInquiry.installationChantier = action.payload;
		},
		updateInquiryListCurrentPage: (state, action) => {
			state.inquiryList.currentPage = action.payload;
		},
		updateInquiryId: (state, action) => {
			state.newInquiry.editID = action.payload
		},
		resetNewInquiry: (state, action) => {
			state = {
				newInquiry: {
					editID: 0,
					customerInfo: {
						companyID: '',
						customerID: '',
						designation: '',
						numClient: '',
						nomEntreprise: '',
						nomClient: '',
						email: '',
						telephone: '',
						fonction: '',
						refChantier: ''
					},
					origineDemande: {
						typeDemande: ''
					},
					dureeLocation: {
						dateDebut: '',
						dateFin: '',
						commentaire: '',
						affaireObtenue: '',
						dateReponse: '',
						tempsEstime: ''
					},
					demandeClient: {
						badgeInput: "",
						badges: []
					},
					googleMaps: {
						formattedAddress: '',
						latitude: '',
						longitude: '',
						placeId: ''
					},
					filtre: {
		
					},
					localisationChantier: {
						adresseLivraison: '',
						accessibiliteLivraison: '',
						contactLivraison: '',
						livraison: '',
						telLivraison: ''
					},
					installationChantier: {
						adresseInstallation: '',
						accessibiliteInstallation: '',
						contactInstallation: '',
						installation: '',
						telInstallation: '',
					},
					commentaire: ''
				},
				inquiryList: {
					inquiries: [],
					limit: 10,
					currentPage: 1,
					totalPages: 1
				}
			}
		}
	},
	extraReducers: builder => {
		builder.addCase(getInquiryList.fulfilled, (state, action) => {
			state.inquiryList = {
				...state.inquiryList,
				inquiries: action.payload.inquiries,
			};
		}),
		builder.addCase(createCustomer.fulfilled, (state, action) => {
			const customerID = action.payload.customerId;
			state.newInquiry.customerInfo.customerID = customerID;
		}),
		builder.addCase(createEntreprise.fulfilled, (state, action) => {
			const companyID = action.payload.companyID;
			state.newInquiry.customerInfo.companyID = companyID;
		}),
		builder.addCase(createInquiry.fulfilled, (state, action) => {
			if (state.newInquiry.editID === 0)
				state.newInquiry.editID = action.payload.data.inquiryId;
			toast.success('Sauvegarde de la demande effectuée.', {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				theme: "colored",
			});
		}),
		builder.addCase(createInquiry.rejected, (state, action) => {
			toast.error("Erreur lors de la sauvegarde de la demande effectuée, vérifier bien le nom de l'entreprise et le client.", {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				theme: "colored",
			});
		}),
		builder.addCase(editCustomer.fulfilled, (state, action) => {
			toast.success('Les informations du clients ont été modifiée.', {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				theme: "colored",
			});
		}),
		builder.addCase(editCustomer.rejected, (state, action) => {
			toast.error("Erreur lors de la modification des informations client, vérifier bien les informations fournis.", {
				position: "top-center",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				theme: "colored",
			});
		})
	}
})

export const {
	resetNewInquiry,
	updateInquiryId,
	updateEntrepriseCoordonneeForm,
	updateCustomerCoordonneeForm,
	updateCommentaire,
	updateOneCoordonneeForm,
	updateCoordonneeForm,
	updateDureeLocationForm,
	updateOneDureeLocationForm,
	updateDemandeClientForm,
	updateGoogleMapsForm,
	updateDemandeOrigin,
	updateLocalisationChantierForm,
	updateInstallationChantierForm,
	updateInquiryListCurrentPage,
} = InquirySlice.actions

export default InquirySlice.reducer
