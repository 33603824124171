import { configureStore } from "@reduxjs/toolkit"

import inquiry from 'src/store/inquiry'
import product from 'src/store/product'
import notif from "./notif"

const store = configureStore({
  reducer: {
    inquiry: inquiry,
    product: product,
    notification: notif,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})
export default store
